/* Custom container that mimics Tailwind's responsive and centering */
.container {
  width: 100%;
  padding-right: var(--space-4);
  padding-left: var(--space-4);
  margin-right: auto;
  margin-left: auto;
}

.bg {
  background-image: url(./assets/img/image\ 6.png);
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
}
.bold-heading {
  font-weight: bold;
  font-size: 2rem;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(
    0,
    0,
    0
  ); /* Fallback color */
  background-color: rgba(
    0,
    0,
    0,
    0.4
  ); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* styles.css */

@media (min-width: 640px) {
  .hero-section {
    height: 500px;
  }

  .image-2 {
    min-width: 70%;
    top: 90%;
  }

  .column-section {
    padding-top: 50px; /* Reduce the top padding for the column section */
  }

  .column-item {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

/* Custom button that uses Tailwind's theme colors */
.button {
  background-color: var(--color-primary);
  color: white;
  padding: var(--space-2) var(--space-4);
  border-radius: var(--rounded-md);
  transition: background-color 0.3s;
}

.button:hover {
  background-color: var(--color-primary-dark);
}

/* Example of defining custom properties based on Tailwind's config */
:root {
  --color-primary: #3490dc;
  --color-primary-dark: #2779bd;
  --space-2: 0.5rem;
  --space-4: 1rem;
  --rounded-md: 0.375rem;
}

.navbar {
  background-color: #ffff;
  color: #ffffff;
}

.navbar a {
  color: #ffffff;
  padding: 14px 20px;
  text-decoration: none;
}

.sidebar {
  background-color: #ffff;
  color: primary;
  width: 150px;
}

.sidebar a {
  padding: 16px;
  text-decoration: none;
  display: block;
}

.sidebar a:hover {
  color: #36827f;
}

.outlet {
  flex: 1;
  padding: 20px;
}
